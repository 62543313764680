import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <script src="./libraries/vendor/js/bundle.min.js"></script>
        <script src="./libraries/vendor/js/jquery.appear.js"></script>
        <script src="./libraries/vendor/js/jquery.fancybox.min.js"></script>
        <script src="./libraries/vendor/js/parallaxie.min.js"></script>
        <script src="./libraries/vendor/js/owl.carousel.min.js"></script>
        <script src="./libraries/vendor/js/parallaxie.min.js"></script>
        <script src="./libraries/vendor/js/wow.min.js"></script>
        <script src="./libraries/vendor/js/jquery.cubeportfolio.min.js"></script>
        <script src="./libraries/vendor/js/jquery.themepunch.tools.min.js"></script>
        <script src="./libraries/vendor/js/jquery.themepunch.revolution.min.js"></script>
        <script src="./libraries/vendor/js/morphext.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.actions.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.carousel.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.kenburn.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.layeranimation.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.migration.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.navigation.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.parallax.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.slideanims.min.js"></script>
        <script src="./libraries/vendor/js/extensions/revolution.extension.video.min.js"></script>
        <script src="./libraries/vendor/js/contact_us.js"></script>
        <script src="./libraries/specific/js/script.js"></script>
        <script src="./libraries/specific/js/contact.js"></script>
        <script src="./libraries/vendor/js/TweenMax.min.js"></script>
        <script src="./libraries/specific/js/slick.js"></script>
        <script src="./libraries/specific/js/slick.min.js"></script>
        <script src="./libraries/specific/js/select2.min.js"></script>
        <script src="./libraries/specific/js/notify.min.js"></script>
        <script src="./libraries/vendor/js/contact_us.js"></script>
        <script src="./libraries/specific/js/script2.js"></script>

        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<HomePage/>}/>
                <Route exact path='/products' element={<ProductsPage/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
