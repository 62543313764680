import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import vid from "../libraries/specific/images/hero.mp4";
import {useEffect, useRef, useState} from "react";
import {SoukiService} from "../services/souki.service";
import {useNavigate} from "react-router-dom";
import Loader from "../components/Loader";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const HomePage = () => {
    const [categories, setCategories] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const splideRef = useRef();

    const navigate = useNavigate();

    const mainSliderRef = useRef();
    const thumbsSliderRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (mainSliderRef.current && thumbsSliderRef.current) {
                // Syncing the main slider with the thumbnail slider
                mainSliderRef.current.splide.sync(thumbsSliderRef.current.splide);
            }
        }, 300);
    }, [selectedProduct]);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await SoukiService.fetchCategories();
            setCategories(response.data);
        }

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchLatestProducts = async () => {
            const response = await SoukiService.fetchLatestProducts();
            setLatestProducts(response.data);
        }

        fetchLatestProducts();
    }, []);

    const onCategorySelect = (categoryId) => {
        navigate(`/products?categoryId=${categoryId}`)
    }

    const getCategoryImageUrl = (category) => {
        if (category.images?.length > 0) {
            return `${category.images[0].url}?width=300&height=300`
        } else {
            return 'https://via.placeholder.com/300';
        }
    }

    const handleNextClick = () => {
        splideRef.current.splide.go('>');
    };

    const handlePrevClick = () => {
        splideRef.current.splide.go('<');
    };

    const onProductSelected = async (productId) => {
        const response = await SoukiService.fetchProductById(productId);
        setSelectedProduct(response.data);
    }

    return (
        <>
            <Loader />

            <Navbar />

            <main>
                <section className="p-0" id="home-banner">
                    <div className="slider-area" id="slider-area">
                        <video autoPlay loop muted playsInline>
                            <source src={vid} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <div className="container">
                            <div className="landing-text">
                                <div className="col-12">
                                    <h1 className="main-font text-white"><span className="d-block">Elevate your ambiance with Haddad Fireplace</span>
                                    </h1>
                                    <p className="alt-font text-white my-3">Lebanon's trusted source for exquisite
                                        fireplaces, Stoves, BBQs, and beyond.</p>
                                    <a href="#categories"
                                       className="btn btn-medium btn-rounded btn-red text-capitalize mt-3 mb-5 mb-md-0 scroll">Learn
                                        More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section slide2 all-products" id="all-products">
                    <div className="container position-relative">
                        <div className="row main-heading">
                            <div className="col-12 main-heading text-center">
                                <h2 className="main-font"><span className="d-block text-blue">Latest Products</span>
                                </h2>
                            </div>
                        </div>

                        <div className="row row-padding">
                            <Splide
                                ref={splideRef}
                                options={{
                                    perPage: 3, // Default to 3 items per page
                                    type: 'loop',
                                    arrows: false,
                                    pagination: false,
                                    width: '100%',
                                    gap: '1rem',
                                    focus: 'center',
                                    breakpoints: {
                                        640: {
                                            perPage: 1,
                                            gap: '0.5rem',
                                        },
                                        768: {
                                            perPage: 2,
                                            gap: '0.75rem',
                                        },
                                        1024: {
                                            perPage: 3,
                                            gap: '1rem',
                                        },
                                    },
                                }} aria-label="My Favorite Images">
                                {
                                    latestProducts.map((product, index) => {
                                        return (
                                            <SplideSlide key={`${index}-product`}>
                                                <div className="product-image-carousel">
                                                    <div className="team-image">
                                                        <img src={`${product.mainImage?.url}?height=394&width=450`}
                                                             alt="image"/>
                                                    </div>

                                                    <div className="team-text">
                                                        <h5 className="main-font">{product.name}</h5>
                                                        {/* eslint-disable-next-line no-undef */}
                                                        <a style={{ cursor: 'pointer' }} onClick={() => onProductSelected(product.id)}
                                                           className="btn btn-medium btn-rounded btn-red text-capitalize mt-3 mb-5 mb-md-0">View
                                                            Detail</a>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                        )
                                    })
                                }
                            </Splide>
                        </div>
                        <a onClick={handlePrevClick} className='circle' id="team-circle-left"><i className="lni lni-chevron-left"></i></a>
                        <a onClick={handleNextClick} className='circle' id="team-circle-right"><i className="lni lni-chevron-right"></i></a>
                    </div>
                </section>

                <section className="gallery-sec padding-top padding-bottom bg-2" id="categories">
                    <div className="container">
                        <div className="row categories-container">
                            <div className="row main-heading text-center">
                                <div className="col-12 main-heading text-center">
                                    <h2 className="main-font"><span className="d-block text-blue">Our Categories</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row padding-top portfolio-area">
                                    {
                                        categories.map((cat, index) => {
                                            return (
                                                <div key={index + 'category'} className="col-12 col-md-6 col-lg-3 portfolio-item">
                                                    <div className="portfolio-inner-content">
                                                        <a onClick={() => onCategorySelect(cat.id)}>
                                                            <div className="item-img-holder position-relative">
                                                                <img src={getCategoryImageUrl(cat)}
                                                                     alt="Category Image"/>
                                                            </div>
                                                            <div className="item-detail-area">
                                                                <div className="d-flex justify-content-between">
                                                                    <h4 className="item-name">{cat.name}</h4>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-bg" id="about">
                    <div className="container about-container">
                        <div className="row section-heading">
                            <div className="col-12 main-heading text-center">
                                <h2 className="main-font"><span className="d-block text-blue">About Us</span></h2>
                                <p className="alt-font mt-4">At Haddad Fireplace, we're dedicated to transforming both
                                    indoor and outdoor spaces with our range of top-quality products imported from
                                    Germany, Italy, Poland, Greece, and the Netherlands since 2016. <span
                                        className="d-block mt-3">As the leading provider in Lebanon, we specialize in fireplaces, stoves, outdoor barbecues, boilers, and more. Our mission is to offer a diverse range of heating and outdoor solutions tailored to your needs, accompanied by reliable maintenance services across the country. </span>
                                    <span className="block mt-3">Quality is our hallmark; we ensure high-performance, safety, and customer satisfaction. Our expert team is committed to delivering personalized and professional service, meeting your unique heating and outdoor needs.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-bg">
                    <div className="container about-container">
                        <div className="row section-heading">
                            <div className="col-12 main-heading text-center">
                                <h2 className="main-font"><span className="d-block text-blue">Why Choose Us</span></h2>
                            </div>
                        </div>
                        <div className="why-items">
                            <div>
                                <div className="why-item">
                                    <div className="why-item-box">
                                        <i className="fas fa-thumbs-up"></i>
                                        <a href="#"><h4 className="news-title1 main-font">Premium Quality Selection</h4>
                                        </a>
                                        <p className="para alt-font">At Haddad Fireplace, we offer a curated range of
                                            top-quality heating solutions sourced from leading manufacturers across
                                            Germany, Italy, Poland, Greece, and the Netherlands.</p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="why-item">
                                    <div className="why-item-box">
                                        <i className="fas fa-wrench"></i>
                                        <a href="#"><h4 className="news-title main-font">Comprehensive Maintenance
                                            Services</h4></a>
                                        <p className="para alt-font">Our commitment extends beyond sales. We provide
                                            expert maintenance and repair services, ensuring your heating systems
                                            operate at peak efficiency and remain in optimal condition.</p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="why-item">
                                    <div className="why-item-box">
                                        <i className="fas fa-map-marked-alt"></i>
                                        <a href="#"><h4 className="news-title main-font">Nationwide Reach and
                                            Expertise</h4></a>
                                        <p className="para alt-font">With nationwide coverage in Lebanon, our
                                            experienced team delivers personalized service, striving to meet your unique
                                            heating needs with efficiency and excellence.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="faqs" className="testimonial padding-top padding-bottom position-relative parallax">
                    <div className="bg-overlay"></div>
                    <div className="row main-heading">
                        <div className="col-12 main-heading text-center">
                            <h2 className="main-font"><span className="d-block text-blue">FAQs</span></h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="feedback-slides">
                                    <div className="client-thumbnails">
                                        <div>

                                        </div>
                                    </div>
                                    <div className="client-feedback">
                                        <div>
                                            <div className="item">
                                                <h3 className="user-name">1. What products do you offer?</h3>
                                                <div className="single-feedback">

                                                    <p className="text">We offer a wide range of heating solutions,
                                                        including fireplaces, stoves, outdoor barbecues, boilers, and
                                                        more. These products are sourced from Germany, Italy, Poland,
                                                        Greece, and the Netherlands for their quality and diversity.</p>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <h3 className="user-name">2. Do you provide maintenance services for
                                                    these products?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">Yes, we provide comprehensive maintenance and
                                                        repair services for all the products we offer. Our team ensures
                                                        your heating systems remain in optimal condition.</p>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <h3 className="user-name">3. What are the shipping options
                                                    available?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">We offer various shipping options for your
                                                        convenience. We deliver all across Lebanon.</p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <h3 className="user-name">4. What is your return policy?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">Our return policy is specifically limited to
                                                        exchanges only in the case of a verified manufacturing defect.
                                                        We do not provide refunds under any circumstances. Should you
                                                        receive a product with a confirmed manufacturing fault or
                                                        defect, we will facilitate an exchange for the same item within
                                                        15 days of purchase.</p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <h3 className="user-name">5. Where do you provide maintenance
                                                    services?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">Our maintenance services cover all areas across
                                                        Lebanon. Whether you're in Beirut, Tripoli, Saida or elsewhere,
                                                        we ensure your heating systems are well-maintained.</p>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <h3 className="user-name">6. Can I get technical assistance with a
                                                    product?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">For any technical assistance or product-related
                                                        queries, reach out to our customer support team. We're here to
                                                        help you with any concerns or questions you may have.</p>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <h3 className="user-name">7. Do you take custom orders or offer
                                                    installation services?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">For custom orders or installation services,
                                                        please contact us directly. Our team will assist you in meeting
                                                        your specific requirements and ensuring proper installation.</p>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <h3 className="user-name">8. Do you ship internationally?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">Yes, we do ship internationally. For inquiries
                                                        regarding international shipping, please contact our customer
                                                        support for assistance.</p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <h3 className="user-name">9. How can I get in touch with your customer
                                                    service?</h3>
                                                <div className="single-feedback">
                                                    <p className="text">You can reach our customer service team by +961
                                                        71 21 32 05 or info@haddadfireplace.com , or through the contact
                                                        form on our website.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section slide4 reviews" id="reviews">
                    <div className="container expand-container">
                        <div className="row main-heading">
                            <div className="col-12 main-heading text-center">
                                <h2 className="main-font"><span className="d-block text-blue">Happy Customers</span>
                                </h2>
                            </div>
                        </div>

                        <div id="testimonial-carousal" className="owl-carousel owl-theme testimonial-owl text-center row-padding">
                            <Splide options={{
                                arrows: false,
                                type: 'loop',
                                height: '250px',
                                autoPlay: true,
                            }}>
                                <SplideSlide>
                                    <div className="item">
                                        <div className="icon-quotes mb-4">
                                            <i className="fas fa-quote-left text-red"></i>
                                        </div>

                                        <div className="testimonial-tittle mt-3 mb-3">
                                            <h3 className="mb-0 alt-font">Paul Atallah</h3>
                                        </div>
                                        <div className="description">
                                            <p className="text-white paragraph">Haddad Fireplace exceeded my
                                                expectations with
                                                their chimney maintenance service! The team was incredibly fast and
                                                efficient,
                                                ensuring my fireplace was in top-notch condition. I highly recommend
                                                their
                                                professional and reliable service.</p>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="item">
                                        <div className="icon-quotes mb-4">
                                            <i className="fas fa-quote-left text-red"></i>
                                        </div>

                                        <div className="testimonial-tittle mt-3 mb-3">
                                            <h3 className="mb-0 alt-font">Alain Younes</h3>
                                        </div>
                                        <div className="description">
                                            <p className="text-white paragraph">Thrilled with my recent BBQ purchase
                                                from Haddad
                                                Fireplace! The selection was fantastic, and the staff provided valuable
                                                insights
                                                to help me choose the perfect one for my garden. Quality products and
                                                excellent
                                                customer service make them my go-to for outdoor essentials.</p>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="item">
                                        <div className="icon-quotes mb-4">
                                            <i className="fas fa-quote-left text-red"></i>
                                        </div>

                                        <div className="testimonial-tittle mt-3 mb-3">
                                            <h3 className="mb-0 alt-font">Jessica Bardawil</h3>
                                        </div>
                                        <div className="description">
                                            <p className="text-white paragraph">Couldn't be happier with my
                                                pre-Christmas
                                                chimney purchase from Haddad Fireplace! The process was seamless, and
                                                they went
                                                above and beyond to ensure I had a cozy fireplace in time for the
                                                holidays.
                                                Exceptional products and service – I highly recommend them for all your
                                                fireplace needs!</p>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </div>
                </section>
            </main>

            {
                selectedProduct && (
                    <div>
                        <div className="modal-window" style={{ display: 'block'}}>
                            <div className="modal-body">
                                <header>
                            <span onClick={() => {
                                setSelectedProduct(null)
                            }} className="close-modal"><i></i><i></i></span>
                                </header>
                                <div className="morphic-body">
                                    <div className="container">
                                        <div className="row main-morphic-body">
                                            <div className="morphic-img col-12 col-md-6">
                                                <Splide
                                                    options={{
                                                        type: 'fade',
                                                        heightRatio: 1,
                                                        pagination: false,
                                                        arrows: false,
                                                        cover: true,
                                                    }}
                                                    ref={mainSliderRef}
                                                >
                                                    {[selectedProduct.mainImage, ...selectedProduct.images].map((image, index) => (
                                                        <SplideSlide key={`main-${index}`}>
                                                            <img src={image?.url} alt={`Image ${index}`} />
                                                        </SplideSlide>
                                                    ))}
                                                </Splide>

                                                <div style={{ height: '30px'}}></div>

                                                {/* Thumbnail Slider */}
                                                <Splide
                                                    options={{
                                                        fixedWidth: 100,
                                                        fixedHeight: 64,
                                                        isNavigation: true,
                                                        gap: 10,
                                                        focus: 'center',
                                                        pagination: false,
                                                        cover: true,
                                                        dragMinThreshold: {
                                                            mouse: 4,
                                                            touch: 10,
                                                        },
                                                        breakpoints: {
                                                            600: {
                                                                fixedWidth: 66,
                                                                fixedHeight: 40,
                                                            },
                                                        },
                                                    }}
                                                    ref={thumbsSliderRef}
                                                >
                                                    {[selectedProduct.mainImage, ...selectedProduct.images].map((image, index) => (
                                                        <SplideSlide key={`thumb-${index}`}>
                                                            <img src={image?.url} alt={`Thumbnail ${index}`} />
                                                        </SplideSlide>
                                                    ))}
                                                </Splide>
                                            </div>
                                            <div className="morphic-title col-12 col-md-6">
                                                <h5>{selectedProduct.category.name}</h5>
                                                <h4>{selectedProduct.name}</h4>
                                                <p>{selectedProduct.description}</p>

                                                <div className="row pb-md-4">
                                                    {
                                                        selectedProduct.attributes.map((att, index) => {
                                                            return (
                                                                <div key={`attribute-${index}`} className="col-4 col-md-3 d-flex align-items-center align-items-lg-end">
                                                                    <div className="color-selection">
                                                                        <h6 className="text-center text-md-left text-blue alt-font">{att.name}: {att.value}</h6>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <Footer/>
        </>
    )
}

export default HomePage;
