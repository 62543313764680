const soukiToken = 'test--4c1fd59e-5c03-49cc-8f5f-ec6a62378cff';

const fetchCategories = async () => {
    const response = await fetch('https://api.souki.vip/v1/public/categories', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'souki-token': soukiToken,
            'souki-customer': localStorage.getItem('souki-customer-id') || '',
        },
    });

    return response.json();
}

const fetchLatestProducts = async () => {
    const response = await fetch('https://api.souki.vip/v1/public/products/latest', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'souki-token': soukiToken,
            'souki-customer': localStorage.getItem('souki-customer-id') || '',
        },
    });

    return response.json();
}

const fetchProductByCategory = async (categoryId) => {
    const response = await fetch(`https://api.souki.vip/v1/public/products/by-category/${categoryId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'souki-token': soukiToken,
            'souki-customer': localStorage.getItem('souki-customer-id') || '',
        },
    });

    return response.json();
}

const fetchProductById = async (productId) => {
    const response = await fetch(`https://api.souki.vip/v1/public/products/${productId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'souki-token': soukiToken,
            'souki-customer': localStorage.getItem('souki-customer-id') || '',
        },
    });

    return response.json();
}

const fetchAttributes = async (productId) => {
    const response = await fetch(`https://api.souki.vip/v1/public/search/attributes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'souki-token': soukiToken,
            'souki-customer': localStorage.getItem('souki-customer-id') || '',
        },
    });

    return response.json();
}

const searchProductsByAttributes = async (attributes) => {
    const response = await fetch(`https://api.souki.vip/v1/public/search/attributes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'souki-token': soukiToken,
            'souki-customer': localStorage.getItem('souki-customer-id') || '',
        },
        body: JSON.stringify({nameValues: attributes, end: 999}),
    });

    return response.json();
}

export const SoukiService = {
    fetchCategories,
    fetchProductByCategory,
    fetchLatestProducts,
    fetchProductById,
    searchProductsByAttributes,
    fetchAttributes,
}
