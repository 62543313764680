import logo from '../libraries/specific/images/logo.png';
import logo2 from '../libraries/specific/images/logo2.png';
import {useNavigate} from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
        // Make Sure to Scroll To Top
        window.scrollTo(0, 0);
    }

    const goToLatestProducts = () => {
        navigate('/');

        setTimeout(() => {
            // Scroll To Specific Element in main page
            const element = document.getElementById('all-products');
            element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    }

    const goToCategories = () => {
        navigate('/');

        setTimeout(() => {
            // Scroll To Specific Element in main page
            const element = document.getElementById('categories');
            element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    }

    const goToAboutUs = () => {
        navigate('/');

        setTimeout(() => {
            // Scroll To Specific Element in main page
            const element = document.getElementById('about');
            element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    }

    const goToFAQs = () => {
        navigate('/');

        setTimeout(() => {
            // Scroll To Specific Element in main page
            const element = document.getElementById('faqs');
            element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    }

    const goToContactUs = () => {
        navigate('/');

        setTimeout(() => {
            // Scroll To Specific Element in main page
            const element = document.getElementById('contact');
            element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    }

    return (
        <header className="site-header">
            <nav className="navbar navbar-expand-lg nav-bottom-line center-brand static-nav">
                <div className="container">
                    <a className="navbar-brand scroll" href="#" onClick={goToHome}>
                        <img src={logo} alt="logo" className="logo-default"/>
                        <img src={logo2} alt="logo" className="logo-scrolled"/>
                    </a>
                    <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button"
                            data-toggle="collapse" data-target="#xenav">
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </button>
                    <li className="top-personal-info"><a className="social-icon"
                                                         href="https://api.whatsapp.com/send?phone=96171213205"
                                                         target="_blank"><i className="las la-phone"></i>+961 71 213 205</a>
                    </li>
                    <div className="collapse navbar-collapse" id="xenav">
                        <ul className="navbar-nav" id="container">
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToLatestProducts}>Latest Products</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToCategories}>Categories</a>
                            </li>

                        </ul>
                        <ul className="navbar-nav ml-13 mr-0 mr-lg-4">
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToAboutUs}>About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToFAQs}>FAQs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToContactUs}>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <a href="javascript:void(0)" className="d-inline-block sidemenu_btn" id="sidemenu_toggle">
                    <span></span> <span></span> <span></span>
                </a>
            </nav>

            <div className="side-menu">
                <div className="inner-wrapper">
                    <span className="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
                    <nav className="side-nav w-100">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToHome}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToLatestProducts}>Latest Products</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToCategories}>Categories</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToAboutUs}>About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToFAQs}>FAQs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" onClick={goToContactUs}>Contact Us</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="side-footer w-100">
                        <p>&copy; 2023 Haddad Fireplace. Powered By Klutch</p>
                    </div>
                </div>
            </div>
            <a id="close_side_menu" href="javascript:void(0);"></a>
        </header>
    )
}

export default Navbar;
