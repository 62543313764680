const Footer2 = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <div className="footer-social">
                            <ul className="list-unstyled social-icons social-icons-simple">
                                <li><a className="social-icon wow fadeInUp"
                                       href="https://www.facebook.com/haddadfireplace1/" target="_blank"><i
                                    className="fab fa-facebook-f" aria-hidden="true"></i> </a></li>
                                <li><a className="social-icon" href="mailto:info@haddadfireplace.com"><i
                                    className="fas fa-envelope"></i></a></li>
                                <li><a className="social-icon wow fadeInUp"
                                       href="https://www.instagram.com/haddadfireplace/" target="_blank"><i
                                    className="fab fa-instagram" aria-hidden="true"></i> </a></li>
                            </ul>
                        </div>
                        <p className="company-about fadeIn">&copy; 2023 Haddad Fireplace. Powered By Klutch</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer2;
