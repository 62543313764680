import whatsAppImage from '../libraries/specific/images/wp2.png';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import {Marker} from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


const Footer = () => {
    const position = ['33.965550', '35.679280']

    const openGoogleMaps = () => {
        const url = "https://www.google.com/maps/place/33%C2%B057'36.3%22N+35%C2%B041'06.4%22E/@33.9599975,35.6847526,20z/data=!4m4!3m3!8m2!3d33.9600833!4d35.6851111?hl=en&entry=ttu";
        window.open(url, '_blank')
    }


    // Define your custom icon
    const customIcon = L.icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png', // Your custom icon URL here
        iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png', // Optional: for retina displays
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png', // Optional: icon shadow
        iconSize: [25, 41], // Size of the icon
        iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
        popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
        shadowSize: [41, 41] // Size of the shadow
    });

    return (
        <>
            <div id="wp-button">
                <a
                    href="https://api.whatsapp.com/send?phone=96171213205"
                    target="_blank"><img
                    src={whatsAppImage}
                    alt="WhatsApp"
                /></a>
            </div>

            <section className="section slide5 contact-sec" id="contact">
                <div className="container expand-container">
                    <div className="row row">
                        <div className="col-12 col-lg-7 main-heading">
                            <h2 className="main-font">Questions?<span
                                className="d-block text-blue">Contact us now</span></h2>
                            <form className="row contact-form" id="contact-us-form">
                                <div className="col-12 col-lg-8" id="result"></div>
                                <div className="col-12 col-lg-8">
                                    <input type="text" id="name-input" name="userName" placeholder="Full Name"
                                           className="form-control"/>
                                    <input type="text" id="phone-input" name="userPhone" placeholder="Phone Number"
                                           className="form-control"/>
                                    <input type="email" id="email-input" name="userEmail" placeholder="Email"
                                           className="form-control"/>
                                    <textarea className="form-control" id="message-input" name="userMessage"
                                              rows="6" placeholder="Type Your Message Here"></textarea>
                                    <a id="contact-us-button"
                                       className="btn btn-medium btn-rounded btn-red rounded-pill w-100 contact_btn main-font"
                                       type="submit">Send Message</a>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-lg-5 text-center text-lg-left d-flex align-items-center">
                            <div className="contact-details wow fadeInRight">
                                <MapContainer style={{width: '600px', height: '400px'}} center={position} zoom={13} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker icon={customIcon} eventHandlers={{
                                        click: openGoogleMaps,
                                    }} position={position}></Marker>
                                </MapContainer>
                                <div className="slider-social side-icons">
                                    <ul className="list-unstyled d-flex">
                                        <a className="social-icon" href="https://www.facebook.com/haddadfireplace1/"
                                           target="_blank"><i className="fab fa-facebook-f"></i></a>
                                        <a className="social-icon" href="https://www.instagram.com/haddadfireplace/"
                                           target="_blank"><i className="fab fa-instagram"></i></a>
                                        <a className="social-icon" href="mailto:info@haddadfireplace.com"><i
                                            className="fas fa-envelope"></i></a>
                                        <a className="social-icon"
                                           href="https://api.whatsapp.com/send?phone=96171213205" target="_blank"><i
                                            aria-hidden="true" className="fas fa-phone-volume"></i></a>

                                    </ul>
                                </div>
                                <div className="side-footer w-100">
                                    <p>&copy; 2023 Haddad Fireplace. Powered By Klutch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;
