import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {SoukiService} from "../services/souki.service";
import Footer2 from "../components/Footer2";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const ProductsPage = () => {
    const [attributes, setAttributes] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const location = useLocation();

    const mainSliderRef = useRef();
    const thumbsSliderRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (mainSliderRef.current && thumbsSliderRef.current) {
                // Syncing the main slider with the thumbnail slider
                mainSliderRef.current.splide.sync(thumbsSliderRef.current.splide);
            }
        }, 300);
    }, [selectedProduct]);

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo(0, 0);
        }

        scrollToTop();
    }, []);

    useEffect(() => {
        const categoryId = new URLSearchParams(location.search).get('categoryId');
        setSelectedCategoryId(categoryId);
    }, [location.search]);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await SoukiService.fetchCategories();
            setCategories(response.data);
        }

        fetchCategories();
    }, [])

    useEffect(() => {
        const fetchProductsByCategory = async () => {
            const response = await SoukiService.fetchProductByCategory(selectedCategoryId);
            setProducts(response.data);
        }

        if (selectedCategoryId) {
            fetchProductsByCategory();
        }
    }, [selectedCategoryId]);

    useEffect(() => {
        const fetchAttributes = async () => {
            const response = await SoukiService.fetchAttributes();
            setAttributes(response.data);
        }

        fetchAttributes();
    }, []);

    const onSelectChange = () => {
        const attributeValues = attributes.map(attribute => {
            return {
                name: attribute.name,
                values: [document.getElementById(attribute.name + '-select').value]
            }
        });

        const searchProducts = async () => {
            const response = await SoukiService.searchProductsByAttributes(attributeValues);
            const products = response.data;
            // Filter products by selected category
            if (selectedCategoryId) {
                setProducts(products.filter(product => product.category?.id === selectedCategoryId));
            } else {
                setProducts(products);
            }
        }

        searchProducts();
    }

    const onProductSelected = async (productId) => {
        const response = await SoukiService.fetchProductById(productId);
        setSelectedProduct(response.data);
    }

    return (
        <>
            <Loader/>

            <Navbar/>

            <main>
                <section className="detail-page-sec padding-top padding-bottom bg-2" id="detail-page-sec">
                    <div className="container">
                        <div className="row">
                            <div className="filter-sec col-12 col-lg-3">
                                {
                                    attributes.map((attribute, index) => {
                                        return (
                                            <div className='select-filter'>
                                                <h6>{attribute.name}</h6>
                                                <select id={attribute.name + '-select'} onChange={onSelectChange}
                                                        className="filter-option">
                                                    <option value="" disabled
                                                            selected>Select {attribute.name.toLowerCase()}</option>
                                                    {
                                                        attribute.values.map((value, index) => {
                                                            return (
                                                                <option key={`${attribute.name}-index`}
                                                                        value={value}>{value}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-12 col-lg-9">
                                <ul className="row nav nav-pills" id="pills-tab" role="tablist">
                                    {
                                        categories.map((category, index) => {
                                            return (
                                                <li key={category + index} className="col-6 col-lg-3 nav-item pl-1">
                                                    <a className={`nav-link ${category.id === selectedCategoryId ? 'active' : ''}`}
                                                       data-toggle="pill"
                                                       role="tab" aria-controls="pills-profile"
                                                       href='#'
                                                       onClick={() => setSelectedCategoryId(category.id)}
                                                       aria-selected="true">

                                                        <span className="pill-name">{category.name}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active products-container">
                                        {
                                            products.map((product, index) => {
                                                return (
                                                    <a onClick={() => onProductSelected(product.id)} key={product.id + index}
                                                       className="food-list">
                                                        <div className="list-overlay"></div>
                                                        <div className="rates d-flex justify-content-between">
                                                            <div className="info">
                                                                <img
                                                                    src={`${product.mainImage.url}?width=215&height=150&quality=70`}
                                                                    alt="Product Image"/>
                                                                <h6 className="main-heading">{product.name}</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {
                selectedProduct && (
                    <div>
                        <div className="modal-window" style={{ display: 'block'}}>
                            <div className="modal-body">
                                <header>
                            <span onClick={() => {
                                setSelectedProduct(null)
                            }} className="close-modal"><i></i><i></i></span>
                                </header>
                                <div className="morphic-body">
                                    <div className="container">
                                        <div className="row main-morphic-body">
                                            <div className="morphic-img col-12 col-md-6">
                                                <Splide
                                                    options={{
                                                        type: 'fade',
                                                        heightRatio: 1,
                                                        pagination: false,
                                                        arrows: false,
                                                        cover: true,
                                                    }}
                                                    ref={mainSliderRef}
                                                >
                                                    {[selectedProduct.mainImage, ...selectedProduct.images].map((image, index) => (
                                                        <SplideSlide key={`main-${index}`}>
                                                            <img src={image?.url} alt={`Image ${index}`} />
                                                        </SplideSlide>
                                                    ))}
                                                </Splide>

                                                <div style={{ height: '30px'}}></div>

                                                {/* Thumbnail Slider */}
                                                <Splide
                                                    options={{
                                                        fixedWidth: 100,
                                                        fixedHeight: 64,
                                                        isNavigation: true,
                                                        gap: 10,
                                                        focus: 'center',
                                                        pagination: false,
                                                        cover: true,
                                                        dragMinThreshold: {
                                                            mouse: 4,
                                                            touch: 10,
                                                        },
                                                        breakpoints: {
                                                            600: {
                                                                fixedWidth: 66,
                                                                fixedHeight: 40,
                                                            },
                                                        },
                                                    }}
                                                    ref={thumbsSliderRef}
                                                >
                                                    {[selectedProduct.mainImage, ...selectedProduct.images].map((image, index) => (
                                                        <SplideSlide key={`thumb-${index}`}>
                                                            <img src={image?.url} alt={`Thumbnail ${index}`} />
                                                        </SplideSlide>
                                                    ))}
                                                </Splide>
                                            </div>
                                            <div className="morphic-title col-12 col-md-6">
                                                <h5>{selectedProduct.category.name}</h5>
                                                <h4>{selectedProduct.name}</h4>
                                                <p>{selectedProduct.description}</p>

                                                <div className="row pb-md-4">
                                                    {
                                                        selectedProduct.attributes.map((att, index) => {
                                                            return (
                                                                <div key={`attribute-${index}`} className="col-4 col-md-3 d-flex align-items-center align-items-lg-end">
                                                                    <div className="color-selection">
                                                                        <h6 className="text-center text-md-left text-blue alt-font">{att.name}: {att.value}</h6>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <Footer2/>
        </>
    )
}

export default ProductsPage;
